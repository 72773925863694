<template>
  <div class="template-email">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="header-team mt-3" v-else>
      <v-list
        dense
        class="list-ensemble mt-3"
        v-if="getAllTemplateEmail && getAllTemplateEmail.length"
      >
        <v-list-item
          class="list-ensemble-item"
          v-for="(mailTemplate, index) in getAllTemplateEmail"
          :key="mailTemplate.id"
          :class="{
            'border-top-solid': index == 0
          }"
        >
          <v-list-item-content>
            <v-list-item-title class="flex center">
              <div class="font-text font-sz-14 bold-700">
                {{ mailTemplate.title }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              class="icon-action mr-2"
              small
              outlined
              color="#5C2DD3"
              :title="$t('view')"
              @click.prevent.stop="handleShowClick(mailTemplate)"
            >
              <font-awesome-icon class="img-h-19" icon="eye" />
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn
              class="icon-action mr-2"
              small
              outlined
              color="#5C2DD3"
              :title="$t('update')"
              @click.prevent.stop="handleUpdateClick(mailTemplate)"
            >
              <font-awesome-icon class="img-h-19" icon="pencil-alt" />
            </v-btn>
          </v-list-item-action>
          <v-list-item-action class="ml-0">
            <v-btn
              class="icon-action"
              :title="$t('deleted')"
              small
              outlined
              color="#5C2DD3"
              @click.prevent.stop="handleDeleteClick(mailTemplate)"
            >
              <font-awesome-icon class="img-h-19" icon="trash-alt" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div v-else class="div-aucun-list">
        <div class="titre">
          {{
            $i18n.locale === 'fr'
              ? 'Aucune Template E-mail '
              : 'No Template E-mail'
          }}
        </div>
      </div>
    </div>
    <!-- DELETED TEMPLATE -->
    <v-dialog
      v-model="deleteMailTemplateModal"
      max-width="500"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Supprimer une Template E-mail'
                : 'Delete E-mail Template'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('deleteMailTemplateModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{ $t('msgdeleted', { msg: 'template' }) }}
            <strong>
              {{
                TemplateMailToDelete ? TemplateMailToDelete.title : ''
              }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTemplateEmailLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTemplateEmailError" class="error-msg">
              <ul v-if="Array.isArray(getTemplateEmailError)">
                <li v-for="(e, index) in getTemplateEmailError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTemplateEmailError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="supprimerTemplateMail"
            :loading="getTemplateEmailLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('deleteMailTemplateModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SHOW TEMPLATE -->
    <v-dialog
      v-model="showMailTemplateModal"
      max-width="1200"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header text-capitalize">
            Template E-mail :
            {{
              emailTemplateToShow && emailTemplateToShow.title
                ? emailTemplateToShow.title
                : ''
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('showMailTemplateModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div
            v-if="emailTemplateToShow"
            v-html="emailTemplateToShow.body"
          ></div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTemplateEmailLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTemplateEmailError" class="error-msg">
              <ul v-if="Array.isArray(getTemplateEmailError)">
                <li v-for="(e, index) in getTemplateEmailError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTemplateEmailError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog('showMailTemplateModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- UPDATE Template Email -->
    <v-dialog
      v-model="showTemplateMailToUpdate"
      max-width="1200"
      persistent
      scrollable
      :retain-focus="false"
      :eager="true"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Modifier une template E-mail'
                : 'Edit E-mail Template'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModalTemplete('showTemplateMailToUpdate')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form
            ref="showTemplateMailToUpdate"
            class="mt-2"
            v-if="TemplateMailToUpdate"
          >
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('title')"
                  v-model="TemplateMailToUpdate.title"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('title') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>

                <v-textarea
                  dense
                  :label="$t('object')"
                  v-model="TemplateMailToUpdate.subject"
                  :persistent-placeholder="true"
                  rows="2"
                  outlined
                  color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('object') + ' ' + $t('msgOblg')]"
                ></v-textarea>
              </v-col>
              <v-col>
                <div class="mb-1 title-fields">
                  {{ $i18n.locale === 'fr' ? 'Champs' : 'Fields' }}
                  <div
                    class="font-text font-sz-12 ml-3 color-crm"
                    v-if="copierText"
                  >
                    {{ $i18n.locale === 'fr' ? 'Text copier' : 'Text copy' }}
                  </div>
                </div>
                <div
                  v-if="
                    getEmailTemplatesFields && getEmailTemplatesFields.length
                  "
                >
                  <v-text-field
                    v-for="(prop, val) of getEmailTemplatesFields"
                    :key="prop.id"
                    :value="val"
                    dense
                    disabled
                    outlined
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                    class="input-fields-copy"
                  >
                    <template #append>
                      <v-icon
                        class="color-crm"
                        :title="$t('copy')"
                        @click.prevent.stop="
                          CopyFields({ prop: prop, val: val })
                        "
                        >mdi-content-copy</v-icon
                      >
                    </template>
                  </v-text-field>
                </div>
                <div v-else class="div-aucun">
                  <div class="titre">
                    {{ $i18n.locale === 'fr' ? 'Aucun Champs' : 'No Fields' }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="showTemplateMailToUpdate"
              ><v-col>
                <editor
                  v-model="TemplateMailToUpdate.body"
                  :init="computedDataTinymce.init"
                ></editor></v-col
            ></v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTemplateEmailLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTemplateEmailError" class="error-msg">
              <ul v-if="Array.isArray(getTemplateEmailError)">
                <li v-for="(e, index) in getTemplateEmailError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTemplateEmailError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleUpdateEmailTemplate"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn
            text
            @click="hideModalTemplete('showTemplateMailToUpdate')"
            small
            >{{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script src="//cdn.tinymce.com/4/tinymce.min.js"></script>
<script>
import { mapActions, mapGetters } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
export default {
  data() {
    return {
      TemplateMailToDelete: null,
      TemplateMailToUpdate: null,
      emailTemplateToShow: null,
      open: false,
      showTemplateMailToUpdate: false,
      copierText: false,
      initLoading: false,
      showMailTemplateModal: false,
      deleteMailTemplateModal: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'getAllTemplateEmail',
      'getMailTemplateLoading',
      'getTemplateEmailError',
      'getTemplateEmailLoading',
      'getEmailTemplatesFields',
      'getImageTemplateList'
    ]),
    computedImageList() {
      return this.getImageTemplateList.map(item => {
        return { title: item.title, value: item.url }
      })
    },
    computedDataTinymce() {
      return {
        init: {
          selector: '#editor',
          init_instance_callback: function(editor) {
            var freeTiny = document.querySelector(
              '.tox-notifications-container'
            )
            if (freeTiny) {
              freeTiny.style.display = 'none'
            }
          },
          height: 450,
          fullpage_default_doctype: '<!DOCTYPE html>',
          fullpage_default_encoding: 'UTF-8',
          plugins: [
            'advlist autolink lists link image  print preview hr',
            'searchreplace visualblocks code ',
            'insertdatetime  table paste imagetools charmap'
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt',
          toolbar_mode: 'floating',
          powerpaste_allow_local_images: true,
          language: this.$i18n.locale === 'fr' ? 'fr_FR' : 'en',
          toolbar:
            ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify |  link image |' +
            ' bullist  numlist outdent indent  |  forecolor backcolor  |' +
            '   print preview  fullpage |' +
            ' hr charmap',
          menubar: 'favs file edit view insert format   ',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat '
            }
          },
          contextmenu: false,
          removed_menuitems: 'paste pastetext',
          block_formats: 'box=alert-box; badge=badge; footer=footer',
          formats: {
            'alert-box': {
              block: 'div',
              styles: {
                'background-color': '#f4f5f9',
                padding: '20px'
              }
            },
            footer: {
              block: 'div',
              styles: {
                'background-color': ' #c7c7c7',
                padding: '20px'
              }
            },
            badge: {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '.1rem solid transparent',
                'border-radius': '50px',
                padding: '1rem',
                'margin-top': '0px',
                'margin-bottom': '1rem',
                color: '#2276d2',
                'box-shadow': '2px 3px #d6d7da',
                'background-color': '#008cd6',
                color: 'white'
              }
            }
          },
          /* enable title field in the Image dialog*/
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          image_list: success => success(this.computedImageList),
          file_picker_types: 'image',
          images_upload_handler: async (blobInfo, success, failure) => {
            let param = new FormData()
            param.append('image', blobInfo.blob())
            this.setLisImage(param)
              .then(response => {
                success(response.data.data.url)
              })
              .catch(error => {
                failure('HTTP Error: ' + error.message)
              })
          }
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchAllEmailTemplates',
      'resetErrorEmailTemplate',
      'deleteTemplateEmail',
      'updateEmailTemplate',
      'setLisImage',
      'getAllImageTemplate'
    ]),
    closeDialog(ref) {
      this[ref] = false
      this.resetModal()
    },
    resetModal() {
      this.TemplateMailToDelete = null
      this.emailTemplateToShow = null
      this.resetErrorEmailTemplate()
    },
    hideModalTemplete(template) {
      this[template] = false
      if (template == 'showTemplateMailToUpdate') {
        this.$refs.showTemplateMailToUpdate.resetValidation()
      }
      this.resetModal()
    },
    CopyFields(text) {
      // Create a temporary textarea element
      const copyTextarea = document.createElement('textarea')
      copyTextarea.style.position = 'fixed'
      copyTextarea.style.opacity = '0'
      copyTextarea.value = text

      // Append the textarea to the document
      document.body.appendChild(copyTextarea)

      // Select the text in the textarea
      copyTextarea.select()

      // Use the Clipboard API to copy the selected text
      if (navigator.clipboard) {
        this.copierText = true
        navigator.clipboard
          .writeText(text)
          .then(() => {})
          .catch(err => {})
          .finally(() => {
            // Remove the temporary textarea
            document.body.removeChild(copyTextarea)
            setTimeout(() => {
              this.copierText = false
            }, 800)
          })
      } else {
        // Remove the temporary textarea
        document.body.removeChild(copyTextarea)
      }
    },
    handleUpdateClick(template) {
      this.TemplateMailToUpdate = template
      this.showTemplateMailToUpdate = true
    },
    handleUpdateEmailTemplate() {
      if (this.$refs.showTemplateMailToUpdate.validate()) {
        const specific_field = []
        if (this.TemplateMailToUpdate.body != null) {
          for (const [key, value] of Object.entries(
            this.getEmailTemplatesFields
          )) {
            if (this.TemplateMailToUpdate.body.indexOf(value) !== -1) {
              specific_field.push(value)
            }
          }
        }
        this.TemplateMailToUpdate.specific_field = specific_field
        const response = this.updateEmailTemplate({
          TemplateEmailToUpdate: this.TemplateMailToUpdate
        })
        if (response) {
          this.hideModalTemplete('showTemplateMailToUpdate')
        }
      }
    },
    handleDeleteClick(template) {
      this.TemplateMailToDelete = template
      this.deleteMailTemplateModal = true
    },
    async supprimerTemplateMail() {
      const response = await this.deleteTemplateEmail({
        template: this.TemplateMailToDelete
      })
      if (response) {
        this.closeDialog('deleteMailTemplateModal')
      }
    },
    handleShowClick(emailTemplate) {
      // this.open = true
      this.emailTemplateToShow = emailTemplate
      this.showMailTemplateModal = true
    }
  },
  components: {
    Editor
  },
  async mounted() {
    this.initLoading = true
    await this.fetchAllEmailTemplates()
    this.getAllImageTemplate()
    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.template-email {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
  .list-ensemble-item {
    border-top: 0px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-color: #d8d8d8 !important;
  }
  .list-ensemble {
    max-height: calc(100vh - 254px) !important;
    height: calc(100vh - 254px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-ensemble::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .list-ensemble::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-ensemble::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
}
</style>

<style lang="scss">
.tox-notifications-container {
  display: none !important;
}
.template-email {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}
</style>
